<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Online Ödeme">
        <name-holder />
        <card-number />
        <b-row>
          <b-col>
            <card-date />
          </b-col>
          <b-col>
            <card-cvv />
          </b-col>
        </b-row>
        <b-button
          variant="success"
          size="lg"
          block
          @click="setPaid"
        >
          3D Güvenli Ödeme
        </b-button>
      </b-card>
    </validation-observer>
    <b-modal
      v-model="paymentModal"
      title="3D Güvenli Ödeme"
      centered
      size="lg"
      hide-footer
    >
      <form
        ref="paymentForm"
        action="https://app.in4startups.com/api/public/paymentForm"
        target="paymentFrame"
        method="post"
      >
        <input
          type="hidden"
          name="name_holder"
          :value="paymentForm.name_holder"
        >
        <input
          type="hidden"
          name="card_number"
          :value="paymentForm.card_number"
        >
        <input
          type="hidden"
          name="card_date"
          :value="paymentForm.card_date"
        >
        <input
          type="hidden"
          name="card_cvv"
          :value="paymentForm.card_cvv"
        >
        <input
          type="hidden"
          name="taxes"
          :value="dataItem.taxes"
        >
        <input
          type="hidden"
          name="subtotal"
          :value="dataItem.subTotal"
        >
        <input
          type="hidden"
          name="taxes_total"
          :value="dataItem.taxesTotal"
        >
        <input
          type="hidden"
          name="grand_total"
          :value="dataItem.grandTotal"
        >
        <input
          type="hidden"
          name="tax_rate"
          :value="dataItem.taxes"
        >
      </form>
      <b-embed
        name="paymentFrame"
        :src="'https://app.in4startups.com/api/public/paymentForm?token=' + downloadToken"
        @load="submitForm"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BModal, BEmbed,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import NameHolder from '@/views/Dealers/Packages/components/elements/NameHolder.vue'
import CardNumber from '@/views/Dealers/Packages/components/elements/CardNumber.vue'
import CardDate from '@/views/Dealers/Packages/components/elements/CardDate.vue'
import CardCvv from '@/views/Dealers/Packages/components/elements/CardCvv.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Installments from '@/views/Dealers/Packages/components/Installments.vue'

export default {
  name: 'PaymentCard',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BEmbed,
    NameHolder,
    CardNumber,
    CardDate,
    CardCvv,
    // Installments,
  },
  data() {
    return {
      downloadToken: localStorage.getItem('downloadToken'),
      required,
      paymentModal: false,
      submitStatus: false,
    }
  },
  computed: {
    paymentForm() {
      return this.$store.getters['dealerPackages/paymentForm']
    },
    dataItem() {
      return this.$store.getters['dealerPackages/dataItem']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  methods: {
    async setPaid() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.paymentModal = true
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    submitForm() {
      this.$nextTick(async () => {
        const form = await this.$refs.paymentForm
        if (form) {
          if (!this.submitStatus) {
            form.submit()
            this.submitStatus = true
          }
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
