<template>
  <b-card title="Sipariş Özeti">
    <b-list-group>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Paket Adı
        </div>
        <div>{{ dataItem.title }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Ara Toplam
        </div>
        <div>{{ dataItem.subTotal | toNumber }} ₺</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Vergiler
          <div class="text-muted font-small-2">
            KDV (%18)
          </div>
        </div>
        <div>{{ dataItem.taxesTotal | toNumber }} ₺</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Toplam Ödenecek Tutar
          <div class="text-muted font-small-2">
            Vergiler Dahil
          </div>
        </div>
        <div>{{ dataItem.grandTotal | toNumber }} ₺</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'PreOrderCard',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
