<template>
  <b-card
    no-body
  >
    <b-card-header
      header-bg-variant="primary"
      class="text-center d-block"
    >
      <b-card-title class="text-white">
        {{ dataItem.title }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <ul class="list-unstyled text-center">
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            Rapor Adedi
          </div>
          <div class="font-medium-2 text-primary font-weight-bold">
            {{ dataItem.reports }}
          </div>
          <div class="font-small-2 text-muted">
            (Yıllık)
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            İş Modeli Analizi
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.bma_report === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.bma_report === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            Pazar Analizi
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.ma_report === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.ma_report === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            Teknoloji Hazırlık Seviyesi Analizi
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.trl_report === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.trl_report === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            Finansal Analiz - Değerleme
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.fp_report === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.fp_report === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            Etki Analizi
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.sdg_report === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.sdg_report === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            IN4S Mentorlarına Erişim
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.mentors === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.mentors === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            IN4S Ekosistemine Erişim
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.ecosystem === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.ecosystem === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="border-bottom py-1">
          <div class="font-weight-bold">
            Gelecek Vadedenler Yarışmasına Katılım Hakkı
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.gv_contest === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.gv_contest === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
        <li class="py-1">
          <div class="font-weight-bold">
            IN4S Etkinlerinde Jüri Olma Hakkı
          </div>
          <div
            class="font-medium-2 font-weight-bold"
            :class="dataItem.jury === '1'? 'text-success' : 'text-danger'"
          >
            <FeatherIcon
              :icon="dataItem.jury === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
          </div>
        </li>
      </ul>
    </b-card-body>
    <b-card-footer
      v-if="!isView"
      class="text-center font-weight-bolder font-medium-3 text-primary"
    >
      {{ dataItem.price | toNumber }}₺ + KDV
      <div class="font-small-2 discount-rate text-muted">
        <div v-if="dataItem.discount_rate">
          * %{{ dataItem.discount_rate }} daha avantajlı
        </div>
      </div>
      <b-button
        variant="primary"
        block
        size="lg"
        class="d-flex justify-content-center align-items-center"
        :to="'/dealers/packages/view/' + dataItem.id"
      >
        <FeatherIcon
          icon="ShoppingCartIcon"
          size="22"
          class="mr-1"
        />
        SATIN AL
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardFooter, BButton,
} from 'bootstrap-vue'

export default {
  name: 'PackageCard',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BButton,
  },
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.discount-rate{
    height: 25px;
}
</style>
