<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
    >
      <b-row class="match-height">
        <b-col>
          <package-card
            :data-item="dataItem"
            is-view
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col>
      <pre-order-card :data-item="dataItem" />
      <payment-card />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import PackageCard from '@/views/Dealers/Packages/components/PackageCard.vue'
import PaymentCard from '@/views/Dealers/Packages/components/PaymentCard.vue'
import PreOrderCard from '@/views/Dealers/Packages/components/PreOrderCard.vue'

export default {
  name: 'PackageView',
  components: {
    BRow,
    BCol,
    PackageCard,
    PaymentCard,
    PreOrderCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['dealerPackages/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('dealerPackages/getData', {
        where: {
          'dealer_packages.id': this.$route.params.id,
        },
        array: false,
        isPayment: true,
      })
    },
  },
}
</script>
