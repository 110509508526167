<template>
  <b-form-group
    label="Kart Numarası"
    label-for="cardNumber"
  >
    <validation-provider
      #default="{ errors }"
      name="Kart Numarası"
      rules="required"
    >
      <cleave
        id="cardNumber"
        v-model="paymentForm.card_number"
        class="form-control"
        :raw="false"
        :options="options.creditCard"
        :state="errors.length > 0 ? false:null"
        placeholder="0000 0000 0000 0000"
        data-ref="cardNumber"
        autocomplete="off"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'CardNumber',
  components: {
    BFormGroup,
    ValidationProvider,
    Cleave,
  },
  data() {
    return {
      required,
      options: {
        creditCard: {
          uppercase: true,
          blocks: [4, 4, 4, 4],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    paymentForm() {
      return this.$store.getters['dealerPackages/paymentForm']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>
